@charset "UTF-8";
@media only screen and (max-width: 767px) {
	#vstar-reviews,
	#vstar-noreviews {
		&[qa-page-count="0"] {
			.tt-qa-list {
				margin-top: 20px;
			}
		}
	}
	//Mark移动端-评论区

	#vstar-reviews {
		&.trustoo-rtl {
			.list-review-head {
				.star {
					direction: ltr;
				}
			}

			.head-left {
				.point,
				.reviews-num {
					text-align: center;
				}
			}
		}
	}

	#vstar-reviews {
		width: 100% !important;
		padding: 0px 12px 20px;
		font-size: 12px;

		#reviews-head {
			display: flex;
			flex-direction: column;
			align-items: center;
			padding: 40px 0 20px;

			&.review-head-type2 {
				flex-direction: row;
				flex-wrap: wrap;
				align-items: start;
				padding: 20px 16px 20px;
			}

			.tt-head-right[only-sort] {
				justify-content: end;
			}
		}

		#reviews-head {
			.head-left {
				display: flex;
				flex-direction: column;
				align-items: center;
				padding: 1px;
				margin-bottom: 20px;
				text-align: left;
			}

			.head-center {
				width: fit-content;
				margin: 0 auto;
			}

			.head-button {
				left: 16px;
				width: calc(100% - 50px);
				min-width: 210px;
				padding: 0 5px;

				line-height: 40px;
			}

			.tt-head-title {
				font-size: 20px;
			}

			.tt-head-content {
				justify-content: center;
			}
			.tt-reviews-album {
				width: 100%;
				margin-top: 20px;
			}
			.tt-head-left {
				width: 100%;
			}
			.tt-head-right {
				display: flex;
				width: 100%;
				flex-wrap: wrap;
				justify-content: space-between;
				margin-top: 20px;
				gap: 0;
			}
		}

		#reviews-head.review-head-type2 {
			justify-content: center;

			.head-left {
				margin: 0 auto;
			}

			.head-button {
				position: static;
				display: block;
			}

			.head-sort {
				position: static;
			}
			.product-rating {
				justify-content: center;
			}
		}

		&.qa {
			#reviews-head {
				.tt-head-right {
					align-items: center;
					gap: 20px;
				}
			}

			#reviews-head {
				.head-button {
					width: 100%;
					max-width: 400px;
				}
			}
		}

		.head-left {
			.head-title {
				font-size: 20px;
			}

			.product-rating {
				display: block;
				margin-top: 20px;
				text-align: center;
			}

			.star {
				margin-bottom: 8px;
			}

			.reviews-num {
				font-size: 14px;
				font-weight: 400;
			}
		}

		.head-center {
			.star-raw,
			.star-num {
				font-size: 14px;
			}

			.star-range {
				display: inline-block;
				width: 42.5vw;
				min-width: 150px;
				max-width: 400px;
				background-color: rgba(0, 0, 0, 0.2);
				vertical-align: middle;
			}
		}

		#reviews-footer {
			.footer-button {
				width: auto;
				min-width: 90px;
				height: 45px;
				align-items: center;
				justify-content: center;
				padding: 0 15px;
				border: 1px solid #585858;
				border-radius: 4px;
				margin: 21px auto 21px;
				background-color: rgba(0, 0, 0, 0);
				font-size: 16px;
				line-height: 45px;
				text-align: center;
			}
		}

		//Mark移动端list布局
		.list-review {
			position: relative;

			&.split {
				flex-direction: column;
				.list-review-head {
					margin-bottom: 0;
				}
			}

			.reviews-date {
				right: 0;
				font-size: 12px;
			}

			.list-review-head {
				position: relative;

				.star {
					margin-left: -2px;
					font-size: 0;
				}

				.review-star-item {
					margin-right: 0px;
				}

				.user-name {
					width: 100px;
					font-size: 16px;
					font-weight: 500;
					vertical-align: middle;
				}
			}

			.list-review-body {
				width: 100%;

				.reviews-text {
					width: 100%;
					word-wrap: break-word;

					.display-text {
						opacity: 0.6;
					}
				}

				.img-list {
					min-height: 20px;

					.img-item {
						width: 100px;
						height: 100px;
						margin: 8px 8px 0 0;
						background-color: black;
						background-repeat: no-repeat;
						background-size: 100% auto;
					}
				}

				.resource-list {
					min-height: 20px;

					.resource-item {
						width: 100px;
						height: 100px;
						margin: 8px 8px 0 0;
						background-color: black;
						background-repeat: no-repeat;
						background-size: 100% auto;
					}
				}
			}
		}

		//Mark移动端grid布局
		.grid-review {
			.reviews-date {
				position: static;
				margin: 0px 8px 0 0;
				vertical-align: middle;
			}

			.grid-review-head {
				.grid-mobile {
					margin-top: 10px;
				}
			}

			.related-product {
				.product-name {
					width: 80px;
				}
			}

			.review-row-one {
				.reviews-date {
					display: none;
				}
			}
		}
	}

	// Mark 移动端-全部评论区
	#vstar-reviews.all-review {
		&.trustoo-rtl {
			#reviews-head {
				.head-sort {
					right: auto;
					left: 16px;
				}
			}
		}

		#reviews-head {
			flex-direction: column;
			align-items: start;
			padding: 30px 0 10px;

			.mix-review-title,
			.mix-review-num,
			.mix-review-rating {
				margin: 0 16px 0;
			}

			.head-sort {
				right: 8px;
				bottom: 15px;
			}
		}
	}
	#noreviews-wrapper {
		padding: 40px 12px 20px;
	}
	//Mark移动端-无评论区
	#vstar-noreviews {
		align-items: center;
		margin: 0 auto;

		#tt-first-row {
			width: 100%;
			flex-direction: column;
			align-items: center;
		}

		#tt-buttons {
			width: 100%;
			justify-content: center;
			margin: 20px 0;
		}

		.noreviews-text {
			font-size: 14px;
		}

		.noreviews-button {
			width: 100%;
			height: 36px;
		}
	}

	//Mark 移动端-弹窗评论区
	#vstar-window-review {
		.window-close {
			right: 8px;
		}

		#vstar-reviews .no-review-tip {
			top: 60%;
		}

		#reviews-head {
			flex-direction: column;
		}

		#reviews-head.review-head-type2 {
			.head-sort {
				top: auto;
			}

			.window-close {
				top: 10px;
			}
		}
	}

	.collection-icon-list {
		.star-item {
			margin-right: 0;
		}
		.star-item,
		svg {
			width: 14px !important;
			height: 14px !important;
		}
		.collection-reviews-num {
			font-size: 14px;
		}
	}
}
