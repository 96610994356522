#tt-bs-area,
#tt-bs-detail {
	div:empty {
		display: block;
	}

	* {
		color: black;
		font-size: 12px;
	}
}

@media only screen and (min-width: 768px) {
	#vstar-reviews {
		#tt-bs-list {
			min-height: 108px;
		}
	}
}

//Mark 公共买家秀列表
#tt-bs-area {
	width: 100%;
	box-sizing: border-box;
	margin: 20px 0;
	text-align: center;

	.tt-bs-title {
		font-weight: bold;
	}
	.review-item {
		.tt-video-player,
		.tt-video-icon {
			width: 36px;
			height: 36px;
		}
	}
}
.trustoo-widget {
	#tt-bs-list {
		display: flex;
		width: 100%;
		height: auto;
		flex-wrap: wrap;
		margin-top: 12px;
	}
	#tt-bs-list {
		.review-item {
			position: relative;
			display: flex;
			align-items: center;
			justify-content: center;
		}
	}
}
#vstar-reviews {
	#tt-bs-list {
		gap: 8px;
		.tt-wrapper {
			height: fit-content;
			flex-basis: calc(25% - 6px);
			padding: 0;
			aspect-ratio: 1/1;
		}
	}
}

.tt-wrapper {
	height: auto;
	box-sizing: border-box;

	.review-item {
		position: relative;
		width: 100%;
		box-sizing: border-box;
		animation: growOut 0.5s linear;
		aspect-ratio: 1/1;
		background: no-repeat center/cover;
		cursor: pointer;

		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
		}

		.tt-video-player {
			position: absolute;
			top: 50%;
			left: 50%;
			width: 20px;
			height: 20px;
			transform: translate(-50%, -50%);
		}

		.tt-video-icon {
			width: 20px;
			height: 20px;
		}
	}
}

.mask {
	&::after {
		position: fixed;
		z-index: 9998;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		background-color: rgba(0, 0, 0, 0.4);
		content: "";
	}
}
