@charset "UTF-8";
@media only screen and (min-width: 768px) {
	//MarkPC端-评论区
	#reviews-wrapper {
		margin: 0px auto;
	}

	// 从右到左 阿拉伯语
	#vstar-reviews.trustoo-rtl {
		#reviews-head {
			.product-rating {
				padding: 0 0 0 40px;
				border-right: none;
				border-left: 1px solid var(--line-color);
			}
			.point,
			.reviews-num {
				text-align: center;
			}
			.head-center {
				margin-right: 40px;
				margin-left: 0;
			}
			.head-center {
				right: 240px;
				left: auto;
			}

			.head-button {
				right: auto;
				left: 48px;
			}

			.head-sort {
				margin-right: 12px;
				margin-left: 0;
			}
		}

		#reviews-body {
			.reviews-date {
				right: auto;
				left: 16px;
			}
		}
	}
	#vstar-reviews {
		padding: 0 40px 20px;
	}

	#vstar-reviews:not(.page-width, .wrapper, .site-wrapper) {
		width: 100%;
		max-width: 1200px;
	}

	#reviews-wrapper.container {
		background-color: #0000;

		#vstar-reviews {
			max-width: initial;
			border: 1px solid var(--border-color);
		}
	}

	#vstar-reviews {
		&.qa {
			.tt-head-right {
				gap: 12px;
			}
		}

		#reviews-head {
			display: flex;
			justify-content: space-between;
			padding: 40px 0 30px;

			&.review-head-type2 {
				padding: 40px 0 20px;

				.tt-head-right {
					padding-bottom: 0;
				}
			}
		}

		#reviews-head {
			.tt-head-left {
				display: flex;
				flex-wrap: wrap;
				gap: 20px;
			}

			.tt-head-left {
				align-items: center;
			}

			.head-left {
				text-align: left;
			}

			.head-button {
				min-width: 123px;
				max-width: 300px;
				padding: 0 15px;
				line-height: 36px;
			}
		}
		.tt-head-title {
			font-size: 20px;
			line-height: 20px;
			white-space: nowrap;
		}
		.tt-head-content {
			margin-top: 40px;
		}

		.tt-head-left {
			.head-center {
				width: auto;
				padding: 0 20px 0 40px;
				border-left: 1px solid var(--line-color);
			}
		}

		.head-left {
			.bigPoint,
			.big-point {
				line-height: 32px;
			}

			.star {
				justify-content: center;
				margin-top: 8px;
			}

			.head-title {
				font-size: 20px;
				line-height: 20px;
				white-space: nowrap;
			}

			.reviews-num {
				margin-top: 8px;
				font-size: 14px;
			}

			.product-rating {
				display: flex;
				width: 110px;
				height: 100px;

				box-sizing: content-box !important;
				flex-direction: column;
				justify-content: space-between;
				padding: 0 20px 0 0;
				text-align: center;
			}
		}

		.head-center {
			&.tt-hover {
				.star-raw {
					opacity: 0.4;
				}

				.star-raw.active {
					cursor: pointer;
					opacity: 1;
				}
			}

			.star-raw {
				.star-range {
					display: inline-block;
					width: 150px;
					background-color: rgba(0, 0, 0, 0.2);
					vertical-align: middle;
				}
			}
		}
		.tt-reviews-album {
			width: 224px;
			box-sizing: content-box;
			padding-left: 40px;
			border-left: 1px solid var(--line-color);
		}

		#reviews-head.review-head-type2 {
			.head-left {
				justify-content: center;
				margin-left: 0px;
			}
			.tt-head-content {
				margin-top: 0;
			}

			.star {
				margin-top: 0;
			}

			.head-button {
				top: auto;
				bottom: 25px;
			}

			.head-sort {
				top: auto;
				bottom: 25px;
			}

			.product-rating {
				width: auto;
				height: auto;
				flex-direction: column;
				align-items: start;
				justify-content: start;
				border: none;
				margin-top: 0;
			}
		}

		//Mark PC端-list布局
		.list-review {
			position: relative;
			width: 100%;

			&.split {
				.list-review-head {
					min-width: 180px;
				}
				.author-name {
					display: inline-block;
					max-width: 128px;
				}
			}

			.reviews-date {
				right: 0;
				font-size: 12px;
			}

			.list-review-head {
				position: relative;

				.user-message {
					font-size: 12px;
				}
			}

			.list-review-body {
				width: 100%;

				.reviews-text {
					width: 100%;
					word-wrap: break-word;
				}

				.img-list {
					display: flex;

					.img-item {
						display: flex;
						width: 100px;
						height: 100px;
						align-items: center;
						justify-content: center;
						margin: 0 8px 8px 0;
						background: black;
					}
				}

				.resource-list {
					display: flex;

					.resource-item {
						display: flex;
						width: 100px;
						height: 100px;
						align-items: center;
						justify-content: center;
						margin: 0 8px 8px 0;
						background: black;
					}
				}
			}
		}

		//Mark PC端-grid布局
		.grid-review {
			.grid-review-head {
				.reviews-date {
					position: absolute;
					top: -1px;
					right: 16px;
				}
			}
		}

		#reviews-footer {
			.page-control-button,
			.page-control-item {
				&:hover {
					opacity: 1;
				}
			}
		}
	}

	// Mark PC端全部评论区
	#vstar-reviews.all-review {
		.mix-review-num {
			margin: 0 12px 0;
			font-size: 20px;
		}
	}

	//Mark PC端-无评论
	#noreviews-wrapper {
		width: 100%;
		padding: 40px 30px;
	}

	#noreviews-wrapper.container {
		padding: 0;
		background-color: #0000;
		#vstar-noreviews {
			max-width: initial;
			padding: 40px;
			border: 1px solid var(--border-color);

			.noreviews-button {
				right: 40px;
			}
		}
	}

	#vstar-noreviews.trustoo-rtl {
		.nostar-wrapper > .noreviews-nostar {
			margin-bottom: 4px;
		}

		.noreviews-button {
			right: auto;
			left: 0;
		}
	}

	#vstar-noreviews:not(.page-width, .wrapper, .site-wrapper) {
		min-width: 500px;
		max-width: 1200px;
	}

	#vstar-noreviews {
		position: relative;
		min-width: 500px;
		max-width: 1200px;

		.tt-tabs-list {
			margin-top: 32px;
		}

		.noreviews-center {
			min-width: 200px;
		}

		.nostar-wrapper {
			height: 20px;
		}

		.noreviews-text {
			font-size: 15px;
		}

		.noreviews-button {
			width: auto;
			min-width: 137px;
			max-width: 300px;
			padding: 0 15px;
		}
	}

	//Mark PC端-弹窗评论区
	#vstar-window-review {
		#reviews-head {
			flex-direction: row;
		}

		#reviews-head {
			.window-close {
				right: 0;
			}
		}

		#reviews-head.review-head-type2 {
			.head-sort {
				margin-right: 36px;
			}

			.window-close {
				top: 48px;
				right: 0;
			}
		}
	}
}

@media only screen and (min-width: 768px) {
	.product-icon-list.vstar-star {
		.product-reviews-num:hover,
		.tt-rating-text:hover {
			text-decoration: underline;
		}
	}
}

@media only screen and(max-width: 916px) and (min-width: 768px) {
	#vstar-reviews {
		#reviews-head {
			.tt-head-right {
				padding: 30px 0;
			}
		}
	}
}
