@font-face {
	font-family: "iconfont";
	/* Project id 4017216 */
	src: url("//cdn.vstar.app/static/css/fonts/iconfont.woff2?t=1681525258204")
			format("woff2"),
		url("//cdn.vstar.app/static/css/fonts/iconfont.woff?t=1681525258204")
			format("woff"),
		url("//cdn.vstar.app/static/css/fonts/iconfont.ttf?t=1681525258204")
			format("truetype");
}
#trustoo-allinone .form-submit,
#success-window .window-button > button {
	border-radius: var(--button-radius);
	background-color: var(--button-bg-color);
	color: var(--button-text-color);
}
//Mark 评论弹窗
#add-review,
#trustoo-allinone.trustoo-write-review {
	position: relative;
	z-index: 999999999;
	display: none;
	box-sizing: border-box;

	animation: fade_in 0.15s linear;
	color: var(--text-color);

	.tt-write-content {
		padding: 0 27px 21px;
	}

	&.trustoo-rtl {
		* {
			direction: rtl;
			text-align: right;
		}

		.window-product {
			.product-right {
				margin: 0 8px 0 0;
			}

			.review-star {
				right: 115px;
				left: auto;
			}
		}

		.review-message {
			.small-input {
				padding-right: 10px;
				padding-left: 0;
			}
		}

		.upload-image-area {
			* {
				text-align: center;
			}
		}
	}

	.window-head {
		position: relative;

		.window-close {
			position: absolute;
			top: 22px;
			right: 0;
			cursor: pointer;
		}
	}

	.window-product {
		position: relative;
		display: flex;
		width: 100%;
		align-items: center;
		margin: 0 0 20px;

		.product-image {
			width: 48px;
			height: 48px;
			border: 1px solid #ddd;
			border-radius: 2px;
			background-position: center;
			background-repeat: no-repeat;
		}

		.product-right {
			flex: 1;
			margin-left: 16px;
			font-size: 16px;
			line-height: 18px;
			word-break: break-word;
		}

		.review-star {
			position: absolute;
			bottom: 0;
			left: 115px;
			display: flex;
			cursor: pointer;

			font-size: 0;

			.product-name {
				margin-bottom: 10px;
				color: #000000;
				font-size: 14px;
				font-weight: 600;
				line-height: 14px;
			}
		}
	}

	div:empty {
		display: block;
	}

	.review-message {
		display: flex;
		width: 100%;
		flex-direction: column;

		.error-tip {
			&[hidden] {
				display: none;
			}

			span {
				margin-left: 4px;
			}

			svg {
				vertical-align: middle;
			}
		}

		.error-tip.display {
			margin: 4px 0 0;
		}

		.small-input {
			width: 100%;
			height: 36px;
			min-height: 35px;
			padding-left: 10px;
			border-radius: 4px;
		}

		.tt-input-title {
			font-size: 14px;
		}
		.inline {
			margin-top: 12px;
		}

		.user-input {
			margin-top: 8px;
		}

		textarea.big-input {
			width: 100%;
			height: 120px;
			min-height: 120px;
			padding: 10px;
			line-height: 18px;
			&::placeholder {
				color: var(--light-text-color);
			}
		}

		.small-input,
		.big-input {
			box-sizing: border-box;
			border-radius: 4px;
			font-size: 14px;

			&:focus {
				box-shadow: none;
				outline: none;
			}
		}

		.feedback-area {
			position: relative;
		}

		.feedback-count {
			position: absolute;
			right: 8px;
			bottom: 8px;
			color: var(--light-text-color);
		}

		.form-image-list,
		.upload-area {
			min-height: 105px;
			flex-wrap: wrap;
			align-items: center;
		}

		.uploaded-item.uploading {
			border: 1px dashed #888;
			background-color: #000;
		}

		.form-image-item,
		.uploaded-item {
			position: relative;
			display: flex;
			width: 95px;
			height: 95px;
			align-items: center;
			justify-content: center;
			margin: 10px 8px 0 0;
			background: center no-repeat black;
			background-size: 100% auto;

			.loader {
				border-bottom-color: #0000;
				margin-left: 0;
			}
			.hover-image {
				display: none;
			}
			&:hover {
				.hover-image {
					display: block;
				}
			}
		}

		.upload-image-area,
		.tt-uploader {
			position: relative;
			display: flex;
			width: 95px;
			height: 95px;
			box-sizing: border-box;
			flex-direction: column;
			align-items: center;
			justify-content: space-evenly;
			padding: 5px;
			border: 1px dashed var(--light-text-color);
			border-radius: 4px;
			margin: 10px 8px 0 0;
			background: #0000;
			text-align: center;

			i {
				margin-bottom: 15px;

				&::before {
					font-size: 20px;
				}
			}

			.images-num {
				font-size: 12px;
			}

			.upload-image-text {
				height: auto;
				font-size: 12px;
				line-height: 14px;
			}

			.upload-input {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				cursor: pointer;
				opacity: 0 !important;
			}
		}

		.upload-or {
			margin-right: 10px;
			font-size: 12px;
		}

		.form-submit {
			display: flex;
			width: 100%;
			height: 40px;
			align-items: center;
			justify-content: center;
			margin-top: 20px;
			font-size: 15px;

			&:hover {
				opacity: 0.8;
			}

			&:active {
				opacity: 0.25;
			}
		}

		.text-num {
			position: absolute;
			right: 8px;
			bottom: 25px;
			color: #999999;
			font-size: 12px;
			line-height: 12px;
		}
	}
	.uploaded-item {
		.tt-video-icon {
			width: 28px;
			height: 28px;
		}
	}

	.input-tip {
		color: #e45454;
		font-size: 14px;
		line-height: 12px;
	}

	.tt-error_tip {
		display: inline-block;
		vertical-align: middle;
	}

	.brand-power {
		margin: 22px auto 0;
		color: #909090;
		text-align: center;

		a {
			color: #909090;
			text-decoration: none;

			&:hover {
				text-decoration: underline;
			}
		}
	}

	.discount-tip {
		margin: 15px 0 0;
		font-size: 14px;
		text-align: center;
	}
	.tt-write-rating {
		.tt-rating-title {
			font-size: 14px;
		}
		.vstar-star {
			margin-top: 8px;
		}
		.star-item {
			margin-right: 4px;
			cursor: pointer;
		}
		.vstar-star {
			&[data-type="12"],
			&[data-type="4"] {
				.star-item:not(.nostar) .trustoo-rating-icon > path {
					stroke: none;
				}
			}
		}

		svg path {
			fill: var(--star-color);
			stroke: var(--star-color);
		}
		.star-item.nostar {
			svg path {
				fill: var(--write-bg);
			}
		}
	}
}

#trustoo-allinone {
	&[write-qa] {
		.upload-area,
		.discount-tip,
		.review-star,
		.tt-write-rating,
		.phone-area,
		.phone-tip,
		.title-area,
		.title-tip {
			display: none !important;
		}

		.email-area,
		.feedback-area,
		.feedback-tip {
			display: block !important;
		}
		#tt-qa-private {
			display: flex !important;
		}
	}
	#tt-qa-private {
		display: none;
		align-items: center;
		margin-top: 20px;
		gap: 20px;
		span {
			font-size: 14px;
		}
		label {
			display: flex;
			align-items: center;
			gap: 8px;
		}
		input[type="radio"] {
			accent-color: #000;
		}
	}

	.inline[hidden] {
		display: none;
	}
	.user-input {
		border: 1px solid var(--light-text-color);
		background-color: #0000;
		color: var(--text-color);
	}
}

// ==================提交评论成功弹窗======================
//Mark 成功弹窗
#success-window {
	z-index: 9999;
	display: none;
	box-sizing: border-box;
	padding: 40px 0 55px;

	.window-title {
		margin-top: 70px;
		font-size: 22px;
		text-align: center;
	}

	.window-content {
		max-width: 321px;
		margin: 13px auto 30px;
		font-size: 14px;
		opacity: 0.6;
		text-align: center;
	}

	.window-image {
		width: 75px;
		height: 66px;
		margin: 26px auto 0;
	}

	.window-button {
		text-align: center;

		button {
			width: auto;
			height: 44px;
			box-sizing: border-box;
			padding: 0 15px;
			border: none;
			margin-top: 30px;
			cursor: pointer;
			font-size: 14px;
			white-space: nowrap;

			&:hover {
				opacity: 0.8;
			}

			&:active {
				opacity: 0.25;
			}
		}
	}

	.window-close {
		position: absolute;
		top: 25px;
		right: 25px;
		width: 14px;
		height: 14px;
		cursor: pointer;
		font-size: 0;

		i {
			&::before {
				font-size: 14px;
			}
		}
	}

	div:empty {
		display: block;
	}

	.window-discount {
		max-width: 293px;
		box-sizing: border-box;
		margin: 26px auto 0;
		box-shadow: 2px 2px 8px rgb(0 0 0 / 25%);
		text-align: center;
	}

	.discount-code {
		display: flex;
		min-width: 150px;
		max-width: 72%;
		min-height: 55px;
		align-items: center;
		justify-content: center;
		color: var(--discount-code-color);
		font-size: 18px;
		font-weight: 700;
		line-height: normal;
		word-break: break-all;
	}

	.discount-copy {
		display: flex;
		width: 28%;
		flex: 1;
		align-items: center;
		justify-content: center;
		padding: 0 15px;
		border-left: 1px dashed #979797;
		color: var(--discount-copy-color);
		cursor: pointer;
		font-size: 18px;
	}
}
#trustoo-slide-wrapper {
	position: relative;
	display: none;
}
// Mark 幻灯片写评论
#trustoo-slide.trustoo-write-review {
	display: none;
	overflow: hidden;
	height: auto;
	min-height: 354px;

	input,
	textarea {
		border-radius: 4px;
		&::placeholder {
			color: var(--light-text-color);
		}
	}

	.write-review-container {
		position: absolute;
		top: 0;
		left: 0;
		display: flex;
		align-items: center;
		transition-duration: 0.6s;
		transition-property: left, opacity;
		transition-timing-function: ease-in-out;

		.write-review-slide {
			&.active {
				opacity: 1;
			}

			&[index="4"] {
				justify-content: center;
				padding: 42px 0 55px;
			}

			position: relative;
			display: flex;
			height: 354px;
			box-sizing: border-box;
			flex-direction: column;
			align-items: center;
			padding: 20px 0 0;
			opacity: 0.3;
			transition-duration: 0.4s;
			transition-property: opacity;
			transition-timing-function: ease-in-out;
		}
	}
}

.write-review-slide {
	.upload-file,
	.slide-next,
	.slide-done,
	.slide-finish {
		border-radius: var(--button-radius);
		background-color: var(--button-bg-color);
		color: var(--button-text-color);
	}

	&[index="0"] .discount-tip {
		margin: 0 15px 15px;
		font-size: 14px;
		text-align: center;
	}

	.window-close {
		position: absolute;
		top: 16px;
		right: 16px;
		cursor: pointer;
	}

	.slide-title {
		width: 80%;
		font-size: 20px;
		font-weight: 600;
		text-align: center;
	}

	&[index="3"] {
		.slide-title {
			margin-bottom: 37px;
		}
	}

	.slide-options {
		margin-top: 20px;
	}

	.slide-option {
		display: flex;
		height: 40px;
		box-sizing: border-box;
		align-items: center;
		padding-left: 64px;
		border: 1px solid var(--button-text-color);
		border-radius: var(--button-radius);
		margin-bottom: 12px;
		background-color: var(--button-bg-color);
		color: var(--button-text-color);
		cursor: pointer;
		font-size: 16px;

		&:hover {
			background-color: var(--button-text-color);
			color: var(--button-bg-color);
		}
		.star-item {
			margin-right: 1px;
		}
		&.active {
			.star-item:not(.nostar) > .trustoo-rating-icon > path {
				fill: var(--button-bg-color);
			}
			[data-type="4"] .trustoo-rating-icon > path {
				stroke: var(--button-text-color);
			}

			.star-item.nostar > .trustoo-rating-icon > path {
				fill: var(--button-text-color);
				stroke: var(--button-bg-color);
			}
		}
		.star-item:not(.nostar) > .trustoo-rating-icon > path {
			fill: var(--button-text-color);
		}
		.star-item.nostar > .trustoo-rating-icon > path {
			fill: none;
			stroke: var(--button-text-color);
		}

		.stars {
			display: flex;
			margin-right: 12px;
		}
	}

	.slide-desc {
		margin-top: 8px;
		color: var(--light-text-color);
		font-size: 14px;
		text-align: center;
	}

	&[index="4"] {
		.slide-title {
			line-height: 28px;
		}
		.slide-desc {
			max-width: 332px;
			margin-top: 5px;
		}
	}

	.slide-upload {
		box-sizing: border-box;
		padding: 16px 0;
		margin-top: 20px;
		text-align: center;

		.upload-desc {
			margin-bottom: 12px;
			font-size: 14px;
			text-align: center;
		}

		.upload-image,
		.upload-file {
			position: relative;
			height: 40px;
			border: 1px solid #000;
			margin: 0 auto 0;
			font-size: 14px;
			line-height: 40px;
			text-align: center;

			&:hover {
				background-color: var(--button-text-color);
				color: var(--button-bg-color);
			}
		}

		.upload-input {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			cursor: pointer;
			opacity: 0 !important;
		}

		.slide-separate {
			display: flex;
			align-items: center;
			margin: 12px auto 12px;
		}
	}

	.slide-separate {
		.slide-line {
			height: 1px;
			flex: 1;
			border-bottom: 1px solid var(--light-text-color);
		}

		.slide-or {
			width: auto;
			padding: 0 5px;
			color: var(--light-text-color);
			text-align: center;
		}
	}

	.upload-image-tip,
	.upload-file-tip {
		position: absolute;
		top: 16px;
		left: 24px;
		display: none;
		color: var(--light-text-color);
	}

	.tt-loading-icon {
		display: inline-block;
		margin-right: 4px;
		animation: rotation 1s linear infinite;
		vertical-align: middle;
	}

	.slide-content {
		height: 198px;
		box-sizing: border-box;
		padding: 12px;
		border: 1px solid var(--text-color);
		border-radius: 4px;
		margin-top: 8px;
		color: var(--text-color);
		font-family: inherit;
		font-size: 16px;
		resize: none;
	}

	.slide-next {
		margin-top: 8px;
	}

	.slide-input {
		height: 40px;
		box-sizing: border-box;
		padding: 12px;
		border: 1px solid var(--light-text-color);
		margin-bottom: 12px;
		background-color: #0000;
		color: var(--text-color);
		font-size: 16px;
	}

	.slide-finish {
		margin-top: 34px;
	}

	.input-tip {
		margin: -8px 0 12px;
		color: #e45454;
		font-size: 16px;
	}

	.tt-error_tip {
		display: inline-block;
		margin-right: 5px;
		vertical-align: middle;
	}

	.slide-thank-img {
		margin-top: 26px;
		text-align: center;
	}

	.slide-discount {
		margin: 18px 0 -10px;
		box-shadow: 2px 2px 8px rgb(0 0 0 / 25%);
	}

	.discount-code {
		flex: 1;
		color: var(--discount-code-color);
		font-weight: 700;
	}

	.discount-copy {
		width: 96px;
		border-left: 1px dashed #979797;
		color: var(--discount-copy-color);
		cursor: pointer;
	}

	.discount-code,
	.discount-copy {
		display: flex;
		min-height: 55px;
		box-sizing: border-box;
		align-items: center;
		justify-content: center;
		padding: 8px;
		font-size: 18px;
		line-height: normal;
		word-break: break-all;
	}

	.next-slide {
		height: 40px;
		border: 1px solid #333;
		cursor: pointer;
		font-size: 16px;
		text-align: center;

		&[disabled] {
			opacity: 0.4;
		}

		&:not([disabled]):hover {
			background-color: var(--button-text-color);
			color: var(--button-bg-color);
		}
	}

	.back-slide {
		left: 16px;
	}

	.slide-skip {
		right: 16px;
		width: auto !important;
		height: auto;
		border: none;
		color: var(--text-color);
	}

	.slide-skip,
	.back-slide {
		position: absolute;
		bottom: 16px;
		border: none;
		background-color: #0000 !important;
		color: var(--text-color);
		cursor: pointer;
		font-size: 14px;

		&:hover {
			color: var(--text-color) !important;
			opacity: 0.7;
		}
	}
}
#trustoo-allinone {
	.trustoo-rating-icon {
		width: 24px;
		height: 24px;
	}
	.vstar-star[data-type="6"] {
		.trustoo-rating-icon {
			width: 32px;
			height: 32px;
		}
	}
}
#trustoo-allinone > .tt-write-content {
	/* WebKit 浏览器滚动条样式（Chrome、Safari、Edge） */
	&::-webkit-scrollbar {
		width: 8px; /* 滚动条宽度 */
		height: 8px; /* 水平滚动条高度 */
	}

	&::-webkit-scrollbar-track {
		background: transparent; /* 轨道透明，使用系统默认颜色 */
	}

	&::-webkit-scrollbar-thumb {
		border-radius: 4px; /* 圆角效果 */
		background-color: rgba(0, 0, 0, 0.3); /* 半透明滑块，保持系统风格 */
	}

	&::-webkit-scrollbar-thumb:hover {
		background-color: rgba(0, 0, 0, 0.5); /* 悬停时稍微加深 */
	}
	/* Firefox 浏览器滚动条样式 */
	.scroll-container {
		scrollbar-color: auto; /* 系统默认颜色 */
		scrollbar-width: thin; /* 较细的滚动条宽度 */
	}
}
.write-review-slide {
	.trustoo-rating-icon {
		width: 20px;
		height: 20px;
	}
	.vstar-star[data-type="6"] {
		.trustoo-rating-icon {
			width: 28px;
			height: 28px;
		}
	}
}

@media only screen and (min-width: 768px) {
	//Mark PC端-添加评论
	#add-review,
	#trustoo-allinone.trustoo-write-review {
		width: 462px;
		height: auto;
		max-height: 80vh;
		margin: 0 auto;

		.tt-write-content {
			max-height: 80vh;
			overflow-y: auto;
		}

		.window-head {
			display: flex;
			width: 100%;
			flex-direction: column;
			justify-content: center;
			margin-bottom: 24px;
			text-align: center;

			.head-title {
				margin: 24px auto 8px;
				font-size: 24px;
				font-weight: 500;
				line-height: 12px;
			}

			.text-desc {
				color: #999999;
				font-size: 12px;
				line-height: 12px;
			}
		}

		.review-message {
			margin: 0 auto;

			.user-input {
				box-sizing: border-box;
				font-size: 14px;
				line-height: 14px;
			}

			.label-name {
				margin-bottom: 8px;
				color: #333333;
				font-size: 14px;
				line-height: 14px;
			}

			.reviews-window-form-smallInputArea {
				display: flex;
				justify-content: space-between;
				margin-bottom: 16px;
			}

			.review-area {
				position: relative;
				font-size: 0;
			}

			.text-num {
				position: absolute;
				right: 8px;
				bottom: 25px;
				color: #999999;
				font-size: 12px;
				line-height: 12px;
			}

			.form-image-list,
			.upload-area {
				display: flex;

				.hover-image {
					position: absolute;
					top: 0;
					right: 0;
					bottom: 0;
					left: 0;
					background-color: rgba(0, 0, 0, 0.5);
					cursor: pointer;
				}

				.tt-delete_image {
					position: absolute;
					top: 50%;
					left: 50%;
					color: white;
					transform: translate(-50%, -50%);
				}

				.image-hover::after {
					width: 68px;
					height: 68px;
					background-color: rgba(0, 0, 0, 0.5);
					content: "";
				}
			}

			.form-submit {
				width: auto;
				min-width: 124px;
				height: 36px;
				padding: 0 15px;
				border: none;
				cursor: pointer;
				font-size: 14px;
				line-height: 14px;
				text-align: center;
			}
		}

		.review-star {
			margin-right: 4px;
		}
	}

	//Mark PC-幻灯片写评论区
	#trustoo-slide {
		position: relative;
		width: 588px;

		.write-review-slide {
			width: 588px;

			.slide-option {
				width: 342px;
			}

			.slide-upload {
				width: 354px;
			}

			.slide-separate,
			.upload-image,
			.upload-file {
				width: 274px;
			}

			.slide-content {
				width: 540px;
			}

			.slide-discount {
				width: 320px;
			}

			.next-slide,
			.slide-input {
				width: 320px;
			}
		}
	}

	//Mark  PC端-成功评论弹窗
	#success-window {
		position: fixed;
		top: 50%;
		left: 50%;
		width: 438px;
		font-size: 24px;
		transform: translate(-50%, -50%);
	}
}

@media only screen and (max-width: 767px) {
	#trustoo-slide-wrapper {
		position: fixed;
		top: 0;
		left: 0;
		width: 100vw;
	}
	//Mark 移动端-评论弹窗
	#add-review,
	#trustoo-allinone.trustoo-write-review {
		overflow: scroll !important;
		width: 100%;
		height: 100vh !important;
		border-radius: 2px;
		margin: 0 auto;

		.window-head {
			position: relative;
			display: flex;
			width: 100%;

			align-items: start;
			justify-content: center;
			margin-bottom: 32px;

			.head-title {
				margin-top: 30px;
				font-size: 24px;
				font-weight: 500;
				line-height: normal;
			}

			.text-desc {
				color: #999999;
				font-size: 16px;
				line-height: 12px;
			}
		}

		.review-message {
			width: 100%;

			.inline {
				width: 100%;
			}

			.email-area {
				margin-top: 5px;
			}

			.label-name {
				margin-bottom: 8px;
				color: #333333;
				font-size: 14px;
				font-weight: 500;
				line-height: 14px;
			}

			.review-area {
				width: 100%;
				font-size: 0;
			}

			.big-input {
				font-size: 16px;
				line-height: 20px;
			}

			.form-image-list,
			.upload-area {
				display: flex;
				width: 90vw;
				flex-wrap: wrap;

				.form-image-item,
				.uploaded-item {
					.image-delete {
						position: absolute;
						top: -5px;
						right: -5px;
						width: 20px;
						height: 20px;
					}
				}
			}

			.upload-image-area {
				min-width: 61px;
				height: 61px;
				padding: 0 5px;
				margin-top: 10px;
			}

			.image-hover::after {
				width: 68px;
				height: 68px;
				background-color: rgba(0, 0, 0, 0.5);
				content: "";
			}

			.form-submit {
				padding: 0;
				border: none;
				cursor: pointer;
				text-align: center;
			}
		}

		.text-num {
			position: absolute;
			right: 8px;
			bottom: 25px;
			color: #999999;
			font-size: 12px;
			line-height: 12px;
		}
	}

	//Mark 移动端-幻灯片写评论区
	#trustoo-slide {
		width: 100vw;
		margin: 0 auto;
		.write-review-slide {
			width: 100vw;
		}

		.write-review-slide {
			.slide-option,
			.slide-upload {
				width: 300px;
			}

			.slide-content,
			.slide-input,
			.next-slide,
			.slide-discount {
				width: 80%;
			}
		}
	}

	//Mark 移动端-成功弹窗
	#success-window {
		position: fixed;
		top: 50%;
		left: 50%;
		width: 343px;
		min-height: 310px;
		transform: translate(-50%, -50%);
	}
}

@keyframes show_window {
	0% {
		transform: scale(0.6);
	}

	100% {
		transform: scale(1);
	}
}

[class^="trustoo-icon-"] {
	/* use !important to prevent issues with browser extensions that change fonts */
	font-family: "iconfont" !important;
	font-size: 14px;
	/* Better Font Rendering =========== */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	font-style: normal;
	font-variant: normal;
	font-weight: normal;
	line-height: 1;
	speak: never;
	text-transform: none;
}
@keyframes fade_in {
	from {
		opacity: 0.6;
		transform: scale(0.7);
	}
	to {
		opacity: 1;
		transform: scale(1);
	}
}
