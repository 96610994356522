@charset "UTF-8";

#vstar-reviews {
	box-sizing: border-box;
	margin: 0px auto;
}

#vstar-reviews,
#vstar-noreviews {
	border: none;
	clear: both;
	text-align: start;
}

//Mark 标签初始化
.trustoo-widget,
.vstar-widget {
	direction: ltr;

	* {
		direction: ltr;
	}

	a {
		text-decoration: none;
	}

	button,
	p,
	input,
	textarea,
	div {
		padding: 0;
		margin: 0;
		font-size: 12px;
		letter-spacing: normal;
		line-height: normal;
	}

	button {
		min-width: 0;
		min-height: 0;
		border-radius: 0;
	}

	input {
		color: #222;
		text-indent: 0px;

		&::-webkit-input-placeholder {
			color: #999;
		}
	}

	input,
	button,
	select,
	textarea {
		outline: none;
	}

	textarea {
		color: #222;
		resize: none;

		&::-webkit-input-placeholder {
			color: #999;
		}
	}

	img {
		width: auto;
		min-width: auto;
		max-width: initial;
		height: auto;
		min-height: 0;
		border: none !important;
		border-radius: 0px;
		margin-top: 0;
		margin-right: 0;
		margin-bottom: 0;
		margin-left: 0;
		margin-block: 0 !important;
		opacity: 1;
	}

	svg {
		display: inline;
		width: auto;
		height: auto;
		stroke: none;
	}

	div {
		text-align: left;
	}

	div:empty {
		display: block;
	}

	@-webkit-keyframes rotation {
		0% {
			transform: rotate(0deg);
		}
		100% {
			transform: rotate(360deg);
		}
	}

	.loader {
		border: 2px solid #fff;
		border-radius: 50%;
		border-bottom-color: #ff3d00;
		margin-left: 8px;
		-webkit-animation: rotation 1s linear infinite;
		animation: rotation 1s linear infinite;
	}
	.vstar-star {
		width: initial;
	}
}

#vstar-all-reviews * {
	margin-block-start: initial;
}

// 字体文件字体样式初始化
#trustoo-review-detail i[class^="trustoo-icon-"],
#vstar-reviews i[class^="trustoo-icon-"],
#trustoo-allinone i[class^="trustoo-icon-"] {
	font-family: "iconfont" !important;
}

.trustoo-widget,
#trustoo-mask {
	.trustoo-power {
		position: absolute;
		bottom: -30px;
		left: 50%;
		width: 200px;
		height: 30px;
		color: #fff;
		font-size: 12px;
		line-height: 30px;
		text-align: center;
		transform: translateX(-50%);

		.redirect {
			padding: 2px 8px 2px 8px;
			border-radius: 12px;
			background: #444;
			color: #fff;
			font-weight: bold;
			text-decoration: none;
		}
		.line {
			display: inline-block;
			width: 1px;
			height: 8px;
			margin: 0 4px;
			background-color: #fff;
			vertical-align: middle;
		}
		.tt-power-cw {
			font-size: 0;
			vertical-align: middle;
			svg {
				vertical-align: middle;
			}
		}
	}
}

.trustoo-widget.trustoo-rtl {
	* {
		direction: rtl;
		text-align: right;
	}
}

#vstar-reviews,
#vstar-noreviews {
	p,
	div,
	span {
		color: var(--font-color);
	}

	&[show-type="2"] {
		&[qa-page-count="0"],
		&[qa-page-count="1"] {
			#reviews-footer {
				display: none;
			}
		}
	}
	&[qa-page-count="0"] {
		.tt-qa-list {
			min-height: 43px;
			margin-top: 32px;
		}
	}
	.tt-qa-list {
		animation: switch_tab 0.5s linear;
	}

	.tt-qa {
		padding: 20px 0;
		border-bottom: 1px solid var(--line-color);
	}

	.tt-qa {
		.tt-questioner,
		.tt-answerer {
			font-size: var(--author-font-size);
			font-weight: 700;
		}

		.tt-question-date,
		.tt-answer-date {
			margin-left: 12px;
			opacity: 0.6;
		}

		.second-row {
			margin-top: 12px;
			font-size: var(--content-font-size);
		}

		.tt-review-question * {
			color: var(--question-color);
		}

		.tt-reviews-answer {
			padding: 20px;
			border-radius: 8px;
			margin-top: 12px;
			background-color: var(--reply-bg-color);

			.second-row {
				white-space: pre-line;
			}
		}

		.tt-reviews-answer * {
			color: var(--answer-color);
		}

		.tt-question,
		.tt-answer {
			font-size: 15px;
		}
	}

	.tt-qa-helpful {
		margin-top: 12px;
		font-size: 14px;
	}

	.tt-qa-helpful {
		svg {
			margin-left: 12px;

			path {
				fill: #00000000;
				stroke: var(--answer-color);
				stroke-width: 8px;
			}

			&.active path {
				fill: var(--answer-color);
			}
		}
	}
}

#vstar-reviews {
	.page-control-button,
	.page-control-item {
		color: var(--font-color);

		&:hover {
			border: 1px solid var(--font-color-o8);
		}
	}

	.page-control[type="qa"] {
		.page-control-button,
		.page-control-item {
			color: var(--question-color);

			&:hover {
				border: 1px solid var(--question-color-o8);
			}
		}
		.page-control-button path {
			fill: var(--question-color);
		}
	}
}

#vstar-reviews .more-review-btn,
#vstar-reviews .head-button,
#vstar-noreviews .noreviews-button,
#vstar-reviews .head-sort .sort-icon,
#reviews-footer button {
	border: 1px solid var(--button-border-color);
	border-radius: var(--button-radius);
	background-color: var(--button-bd-color);
	color: var(--button-color);
}

#vstar-reviews .sort-icon svg > path {
	fill: var(--button-color);
}

#vstar-reviews .head-button,
#vstar-noreviews .noreviews-button,
#vstar-reviews .head-sort .sort-icon,
#noreviews-wrapper .noreviews-button {
	// 0.7
	&:hover {
		opacity: 0.8;
	}
}

/* =============评论区公共列表样式============ */
//Mark  无评论区
#noreviews-wrapper {
	margin: 50px auto;
	clear: both;
}

#vstar-noreviews {
	margin: 0 auto;

	&[show-type="2"] {
		&[qa-page-count="1"] {
			#reviews-footer {
				display: none;
			}
		}

		// 评论列表，评论分页器，评论more按钮，评论排序不显示
		.noreviews-center {
			display: none;
		}
	}
	&[show-type="1"] {
		.tt-qa-list {
			display: none;
		}
	}

	.noreviews-title {
		font-size: 28px;
	}
	#tt-first-row {
		display: flex;
		justify-content: space-between;
	}
	#tt-buttons {
		display: flex;
		flex-direction: column;
		flex-wrap: wrap;
		gap: 12px;
	}

	.noreviews-center {
		margin-top: 32px;
		animation: switch_tab 0.5s linear;
		text-align: center;

		.noreviews-text {
			margin-top: 4px;
			font-size: 20px;
		}

		.noreviews-nostar {
			justify-content: center;
			text-align: center;
		}
	}

	.noreviews-button {
		height: 40px;
		box-sizing: border-box;
		padding: 0 15px;
		cursor: pointer;
		font-size: 16px;
		text-align: center;
	}

	.star-item {
		display: inline-block;
		margin: 0;
		margin-right: 3px;
	}
}

#reviews-wrapper {
	width: 100%;
}

//Mark  评论区
#vstar-reviews {
	&.trustoo-rtl {
		.related-product > .product-name {
			margin-right: 12px;
			margin-left: 0px;
		}
		#reviews-body {
			.user-verified > svg {
				margin-left: 4px;
			}
		}
	}

	&[layout="list"] {
		&[pagecount="1"] {
			#reviews-body {
				padding-bottom: 60px;
			}
		}
		.tt-review-list {
			animation: switch_tab 0.5s linear;
		}
	}

	&.qa {
		#reviews-head {
			border-bottom: none;
		}

		.head-sort {
			display: none;
		}
		#reviews-head:not(.review-head-type2) {
			.tt-head-right {
				flex-direction: column;
			}
		}
	}

	// 当前显示内容为评论
	&[show-type="1"] {
		// QA列表不显示
		.tt-qa-list,
		.page-control[type="qa"] {
			display: none;
		}

		&[review-page-count="1"] {
			#reviews-footer {
				display: none;
			}
		}
	}

	// 当前显示内容为QA
	&[show-type="2"] {
		&[qa-page-count="1"] {
			#reviews-footer {
				display: none;
			}
		}

		#reviews-body {
			height: auto !important;
		}

		// 评论列表，评论分页器，评论more按钮，评论排序不显示
		.tt-review-list,
		.page-control[type="reviews"],
		.more-review-btn,
		.tt-reviews-sort {
			display: none !important;
		}
	}

	#reviews-head {
		position: relative;
		display: flex;
		width: 100%;
		box-sizing: border-box;
		flex-wrap: wrap;
		border-bottom: 1px solid var(--line-color);

		.head-center {
			cursor: pointer;
			font-size: 12px;
		}

		.head-button {
			display: block;
			height: 40px;
			align-items: center;
			justify-content: center;
			cursor: pointer;
			font-size: 14px !important;
			font-weight: 600;
			text-align: center;
		}
	}
	.tt-head-title {
		font-weight: 600;
	}
	.tt-head-content {
		display: flex;
		width: 100%;
		flex-wrap: wrap;
		align-items: center;
		justify-content: space-between;
	}
	.tt-reviews-album {
		#tt-bs-list {
			margin-top: 0;
		}
	}

	.head-left {
		font-style: normal;

		.star {
			font-size: 0px;
			line-height: normal;
		}

		.head-title {
			font-weight: 600;
		}

		.point {
			font-size: 14px;
		}

		.bigPoint,
		.big-point {
			font-size: 32px;
			font-weight: 700;
		}
	}
	.head-center {
		.star-raw {
			display: flex;
			align-items: center;
		}
	}

	.star-raw {
		line-height: 14px;
		&:not(:first-child) {
			padding-top: 10px;
		}

		.star-classify,
		.star-num {
			font-size: 14px;
		}

		.star-classify {
			display: inline-block;

			.number {
				display: inline-block;
				width: 6px;
			}
		}

		.star-range {
			height: 8px;
			border-radius: var(--button-radius);
			margin: 0 10px 0;
		}
	}

	.head-sort {
		width: 40px;
		height: 40px;
		box-sizing: border-box;

		.sort-content {
			position: relative;
			width: 100%;
			height: 100%;

			.sort-icon {
				display: flex;
				width: 100%;
				height: 100%;
				align-items: center;
				justify-content: center;
				cursor: pointer;
			}
		}
	}

	.head-sort,
	.tt-reviews-sort {
		.sort-popup {
			position: absolute;
			z-index: 100;
			top: 42px;
			right: 0;
			display: none;
			width: auto;
			min-width: auto;
			height: 200px;
			border-radius: 8px;
			animation: sort_list_show 0.1s linear;
			background-color: #fff;
			box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.2),
				0px 2px 10px rgba(0, 0, 0, 0.1);
			transform-origin: 100% 0;

			.sort-list {
				position: relative;
				width: auto;
				height: 100%;
				padding: 15px 50px 0 16px;
				margin: 0;
				list-style: none;
				text-decoration: none;
				font-family .sort-title {
					height: 21px;
					color: black;
					font-size: 16px;
					white-space: nowrap;
				}

				.sort-item {
					height: 19px;
					margin: 16px 0 0 0 !important;
					color: #202223;
					cursor: pointer;
					font-size: 14px;
					line-height: normal;
					white-space: nowrap;
				}
			}

			.sort-active {
				position: absolute;
				top: 53px;
				right: 25px;
			}
		}
	}

	.tt-reviews-sort {
		position: relative;
		margin-top: 12px;
		cursor: pointer;

		.sort-popup {
			top: 25px;
		}
	}

	#reviews-head.review-head-type2 {
		.head-left {
			display: flex;
			align-items: center;

			.star {
				margin-bottom: 0;
				margin-inline-start: 3px;
			}

			.point {
				margin: 0;
				font-size: 24px;
			}

			.reviews-num {
				margin-top: 2px;
				font-size: 14px;
			}
		}

		.product-rating {
			display: flex;
			width: 153px;
			flex-wrap: wrap;
			align-items: center;
		}

		.big-point {
			font-size: 24px;
		}
	}

	#reviews-body {
		position: relative;
		width: 100%;
		box-sizing: border-box;
		margin: 0px auto 0px;

		.tt-review-list {
			position: relative;
			width: 100%;
		}

		.tt-review-avatar {
			position: relative;
			width: 40px;
			height: 40px;
			flex-shrink: 0;
			border-radius: 50%;
			background-color: var(--avatar-bg-color);
			color: var(--avatar-text-color);
			font-size: 16px;
			line-height: 40px;
			text-align: center;

			svg {
				position: absolute;
				right: 0;
				bottom: 0;
				transform: translateX(4px);
			}
		}
		.trustoo-badge-icon {
			path {
				fill: var(--badge-color);
			}
		}

		.user-name {
			margin-right: 6px;
			font-size: 14px;
			font-weight: 700;
			vertical-align: middle;
		}

		.country-name {
			font-size: 13px;
			vertical-align: middle;
		}

		.country-flag {
			transform: scale(0.8);
		}

		.img-item,
		.resource-item {
			position: relative;
			display: flex;
			overflow: hidden;
			align-items: center;
			justify-content: center;
			cursor: pointer;
		}

		.tt-video-player {
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);

			img {
				width: 100%;
				height: 100%;
			}
		}
		.tt-review-title {
			margin-bottom: 8px;
			font-size: 16px;
			font-weight: bold;
		}

		.merchant-reply {
			border-radius: 4px;
			margin-top: 12px;
			background-color: var(--reply-bg-color);
		}

		.review-helpful {
			margin-top: 8px;
			text-align: right;
		}

		.related-product {
			display: flex;
			align-items: center;
			margin-top: 18px;
			background: none;
			text-decoration: none;
		}

		.related-product {
			.product-image {
				width: 40px;
				height: 40px;
			}

			.image,
			.related-product-image {
				width: 100%;
				height: 100%;
				background-position: center;
				background-size: cover;
			}

			.product-name {
				flex: 1;
				margin-left: 12px;
			}
		}

		.reviews-num,
		.reviews-date,
		.star-num,
		.country-name {
			opacity: 0.6;
		}
	}
	.tt-head-right {
		display: flex;
		width: auto;
		gap: 8px;
	}
	.body-item:last-child {
		border: none;
	}

	//Mark 公共-评论区-List
	.list-review {
		box-sizing: border-box;
		padding: 24px 0;
		border-bottom: 1px solid var(--line-color);

		&.split {
			display: flex;
			gap: 20px;
			.star {
				margin-bottom: 16px;
			}
		}
		.author-name {
			font-size: 14px;
		}

		.list-review-head {
			margin-bottom: 16px;
		}

		.star {
			margin-bottom: 8px;
		}

		.img-list {
			display: flex;
			flex-wrap: wrap;
			margin-top: 16px;

			.img-item {
				display: flex;
				align-items: center;
				justify-content: center;
				background: black;
			}
		}

		.resource-list {
			display: flex;
			flex-wrap: wrap;
			margin-top: 16px;

			.resource-item {
				background: black;
			}
			img {
				width: 100%;
				height: 100%;
				object-fit: cover;
			}
		}

		.tt-video-player,
		.tt-video-icon {
			width: 28px;
			height: 28px;
		}
		.merchant-reply {
			padding: 12px;
		}
	}

	// Mark公共-评论区-Grid
	.grid-review {
		position: absolute;
		display: inline-block;
		padding-bottom: 16px;
		border-radius: 8px;
		animation: recover 1s linear;
		vertical-align: top;

		.img-item,
		.resource-item {
			&:not(:first-child) {
				display: none !important;
			}
			position: relative;
			width: 100%;

			border-radius: 8px 8px 0 0;

			img {
				width: 100%;
				border-radius: 8px 8px 0 0;
			}
		}

		.tt-video-player {
			width: 32px;
			height: 32px;
			svg {
				width: 32px;
				height: 32px;
			}
		}

		.grid-review-head {
			position: relative;
			padding: 0 16px;
			margin-top: 16px;

			.user-message {
				margin: 12px 0 12px;

				.user-name {
					padding: 0;
					vertical-align: middle;
				}

				.country-name {
					font-size: 16px;
					vertical-align: middle;
				}
			}

			.reviews-date {
				font-size: 12px;
				line-height: 18px;
			}
		}

		.grid-review-body {
			padding: 0 16px;

			.reviews-text {
				word-wrap: break-word;
			}

			.display-text {
				font-size: 14px;
			}
		}

		.related-product {
			padding-top: 8px;
			border-top: 1px solid var(--line-color);
		}

		.resource-list {
			position: relative;
		}
		.merchant-reply {
			padding: 8px;
		}
	}
	.user-message {
		display: flex;
		align-items: center;
		margin-bottom: 12px;
		gap: 12px;
	}

	.multi-photo-wrapper {
		position: absolute;
		z-index: 1;
		top: 8px;
		right: 8px;
	}

	.review-helpful {
		font-size: 0;

		span {
			font-size: 14px;
			vertical-align: middle;
		}

		svg {
			margin: 0 4px 0 8px;
			cursor: pointer;
			stroke: #fff;
			vertical-align: middle;

			path {
				fill: #00000000;
				stroke: var(--font-color);
				stroke-width: 8px;
			}

			&.active path {
				fill: var(--font-color);
			}
		}
	}

	.merchant-reply {
		.reply-title {
			margin-bottom: 4px;
		}
		.tt-shop-avatar {
			display: flex;
			overflow: hidden;
			width: 32px;
			height: 32px;
			align-items: center;
			justify-content: center;
			border-radius: 50%;
			margin-right: 4px;

			img {
				width: 100%;
				height: 100%;
				object-fit: cover;
			}
		}
	}

	.more-review-btn {
		display: block;
		width: auto;
		min-width: 92px;
		height: 40px;
		padding: 0 15px;
		margin: 0 auto;
		cursor: pointer;
		line-height: 40px;
		text-align: center;
	}

	.review-star-item {
		margin-left: 2px;
	}

	#reviews-footer {
		width: 100%;
		box-sizing: border-box;
		padding: 20px 30px 0;
		border-radius: 4px;

		.loader {
			width: 40px;
			height: 40px;
			border-bottom-color: black;
			margin: 0 auto;
		}
	}

	.page-control {
		display: flex;
		width: 100%;
		justify-content: center;
		padding: 0;
		margin: 0 auto;
		list-style-type: none;
		text-align: center;
	}

	.page-control-item {
		display: inline-block;
		width: 32px;
		height: 32px;
		box-sizing: border-box;
		border: none;
		border-radius: 2px;
		margin: 0;
		margin-left: 8px;
		cursor: pointer;
		font-size: 14px;
		line-height: 32px;
		opacity: 0.6;
		text-align: center;
	}

	.page-control-button {
		display: flex;
		width: 32px;
		height: 32px;
		align-items: center;
		justify-content: center;
		border: none;
		border-radius: 2px;
		margin-left: 8px;
		background: rgba(0, 0, 0, 0);
		color: black;
		cursor: pointer;
		line-height: 32px;
		opacity: 0.6;
	}

	#reviews-footer .active {
		font-size: 16px;
		font-weight: 600;
		opacity: 1;
	}

	.display-text {
		cursor: pointer;
		line-height: 12px;
		opacity: 0.6;
	}

	.no-review-tip {
		padding: 30px 0;
		font-size: 16px;
	}
}

// list grid 共用
#vstar-reviews #reviews-body {
	.review-row-one {
		display: flex;
		justify-content: space-between;
	}

	.user-name {
		margin-right: 6px;
		font-size: 14px;
		font-weight: 700;
		vertical-align: middle;
	}

	.country-name {
		font-size: 13px;
		vertical-align: middle;
	}

	.country-flag {
		transform: scale(0.8);
	}

	.user-verified {
		height: auto;
		margin: 5px 0 0 0px;
		font-size: 0;

		span {
			color: var(--badge-text-color);
			font-size: 14px;
			line-height: 16px;
			vertical-align: middle;
		}
	}

	.img-item,
	.resource-item {
		cursor: pointer;
	}

	.merchant-reply {
		margin-top: 12px;

		.reply-title {
			margin-bottom: 4px;
			font-size: 14px;
		}

		.reply-content {
			font-size: 14px;
			line-height: 22px;
			white-space: pre-line;
			word-wrap: break-word;
		}
	}

	.related-product {
		display: flex;
		align-items: center;
		margin-top: 18px;
		text-decoration: none;
	}

	.reviews-num,
	.reviews-date,
	.star-num,
	.country-name {
		opacity: 0.6;
	}
}

.trustoo-widget {
	.tt-tabs-list {
		border-bottom: 1px solid var(--tab-title-line-color);

		.tt-tab {
			display: inline-block;
			padding-bottom: 6px;
			margin: 0 20px 0 0;
			color: var(--tab-title-color);
			cursor: pointer;
			font-size: 16px;
			font-weight: 400;

			&.active {
				border-bottom: 3px solid var(--tab-title-color);
				font-weight: 600;
			}
		}
	}
}

// 侧边标签
#vstar-tab {
	position: fixed;
	z-index: 11000;
	top: 50%;
	right: 0;
	display: flex;
	width: auto;
	min-width: 103.1px;
	height: 33.64px;
	align-items: center;
	justify-content: center;
	padding: 0 15px;
	border-radius: 0px 0px 8px 8px;
	cursor: pointer;
	font-size: 16px;
	transform: rotate(90deg) translate(50%, 0);
	transform-origin: 100% 0%;
}

//Mark 弹窗评论区
#vstar-window-review {
	position: fixed;
	z-index: 11001;
	top: 0;
	bottom: 0;
	left: 50%;
	width: 100%;
	max-width: 1030px;
	height: 100vh;
	padding: 0 15px;
	overflow-x: hidden;
	overflow-y: scroll;
	transform: translateX(-50%);

	#reviews-wrapper {
		padding: 0;
	}

	#vstar-reviews {
		position: relative;
		width: 100%;
		min-height: 100vh;
		margin: 0;

		&.trustoo-rtl {
			#reviews-head {
				.window-close {
					right: auto;
					left: 0;
				}

				.head-sort {
					right: auto;
					left: 40px;
					display: block;
				}
			}
		}
	}

	#reviews-head {
		font-size: 16px;
	}

	.no-review-tip {
		position: absolute;
		top: 50%;
		left: 50%;
		display: block;
		width: 260px;
		color: #999;
		font-size: 20px;
		line-height: 28px;
		transform: translate(-50%, -50%);
	}

	.window-close {
		position: absolute;
		top: 30px;
		cursor: pointer;
	}
}

.trustoo-body-mask {
	position: absolute;
	z-index: 100;
	top: 0;
	left: 0;
	width: 100%;
	height: 1000px;
	background-color: var(--bg-color);
}

/* collection style */

.star-icon {
	position: absolute;
	top: 50%;
	display: inline-block;
	margin-left: 1.56px;
	transform: translateY(-50%);
}

#icon-list-wrapper {
	display: inline-block;
}

//Mark 商品页星星
.product-icon-list {
	justify-content: start;
	margin: 10px 0 15px !important;
	clear: both;
	font-size: 0;

	&[data-position="beforebegin"] {
		margin: 0 0 5px !important;
	}

	.product-reviews-num,
	.tt-rating-text {
		display: inline-block;
		margin: 2px 6px 0 6px;
		cursor: pointer;
		font-size: 16px;
		font-weight: normal;
		line-height: 14px;
	}

	a {
		text-decoration: none;

		&:active {
			text-decoration: underline;
		}
	}
}

//Mark 收藏页星星
.collection-icon-list {
	display: block;
	margin: 5px 0;
	font-size: 0;

	a {
		text-decoration: none;

		&:active {
			text-decoration: underline;
		}
	}

	div {
		display: inline-block;
		vertical-align: middle;
	}
}

.seal-stars {
	.collection-reviews-num {
		margin-top: 2px;
	}
}

.seal-stars,
.product-icon-list,
.collection-icon-list {
	a {
		text-decoration: none;

		&:active {
			text-decoration: underline;
		}
	}

	svg {
		max-width: initial !important;
		stroke: none;
	}
}

.trustoo-open {
	overflow: hidden;
	margin-right: 17px;
}

.mask,
#trustoo-mask {
	position: fixed;
	top: 0;
	left: 0;
	display: flex;
	width: 100vw;
	height: 100vh;
	align-items: center;
	justify-content: center;
	background-color: rgba(0, 0, 0, 0.6);
}
#trustoo-mask {
	z-index: 9999999;
}
.mask.window-review-mask {
	z-index: 999999;
}

.error-border {
	border: 1px solid rgba(255, 34, 34, 1) !important;
}

.toast {
	position: fixed;
	z-index: 9999999999;
	width: 300px;
	min-height: 50px;
	box-sizing: border-box;
	padding: 15px 30px;
	border-radius: 8px;
	background-color: #000c;
	color: #fff;
	font-size: 16px;
	line-height: 1.4;
	text-align: center;
	transform: translateX(-50%);
	transition: all 0.1s;
}

.mix-review-head {
	margin: 38px;
	font-size: 20px;
	font-weight: 600;
	line-height: 28px;
	white-space: nowrap;
}

@keyframes recover {
	0% {
		opacity: 0;
		transform: scale(0.7);
	}

	100% {
		opacity: 1;
		transform: scale(1);
	}
}

@keyframes show_window {
	0% {
		transform: scale(0.6);
	}

	100% {
		transform: scale(1);
	}
}

@keyframes sort_list_show {
	0% {
		transform: scale(0.7);
	}

	100% {
		transform: scale(1);
	}
}
@keyframes switch_tab {
	0% {
		opacity: 0;
	}

	100% {
		opacity: 1;
	}
}

@media (min-width: 1199px) {
	.viewer-container {
		.viewer-bigLarge:empty,
		.viewer-button:empty {
			display: block;
		}
	}
}

.viewer-container {
	z-index: 999999 !important;
}

#tt-reviews-loading {
	width: 8em;
	padding: 40px 0;
	margin: auto;
	animation-duration: 1.4s;
	text-align: center;
}
#tt-reviews-loading {
	.tt-loading-item:empty {
		display: inline-block;
	}
	.tt-loading-item {
		width: 2em;
		height: 2em;

		border-radius: 100%;

		animation: sk-three-bounce 1.4s ease-in-out 0s infinite both;
		background-color: #888888;
	}

	.tt-loading-1 {
		animation-delay: -0.32s;
	}
	.tt-loading-2 {
		animation-delay: -0.16s;
	}
}

@keyframes sk-three-bounce {
	0%,
	80%,
	100% {
		transform: scale(0);
	}
	40% {
		transform: scale(1);
	}
}

span.rating-badge {
	display: none;
}
